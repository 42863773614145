<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="2">
          <CInput size="" v-model="incident_no" placeholder="Incident No."/>
        </CCol>
          <CCol lg="2" v-if="is_trucking()">
          <CInput v-model="report_by" placeholder="Reported By"/>
        </CCol>
    
        <CCol lg="2"  v-if="!is_trucking()">
          <v-select 
            label="setting_name" 
            :options="[
              {
                setting_name: 'Vehicle',
                id: 'vehicle'
              },
              {
                setting_name: 'Equipment',
                id: 'equipment'
              }
            ]"
            :reduce="item => item.id"
            v-model="asset_type"
            placeholder="Asset Type"
          > 
          </v-select> 
        </CCol> 
        
       <CCol lg="2">
          <Datepicker v-model="incident_date_from" input-class="form-control" placeholder="Incident Date from" />
        </CCol>
        <CCol lg="2">
          <Datepicker v-model="incident_date_to" input-class="form-control" placeholder="Incident Date to" />
        </CCol>
         <CCol lg="2">
        </CCol>
      
        <CCol lg="2" class="float-right">
          <CButton size="sm" block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
        </CCol>
      </CRow>
      <CRow>
        
        
        <CCol lg="4">
        </CCol>
        <CCol lg="4">
       
        </CCol>
         <CCol lg="2">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol>
      </CRow>
  </div>
</template>

<script>
  import config from '../config.js';
  import axios from '../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
  import Datepicker from 'vuejs-datepicker';
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            incident_no: "",
            class_type: "all",
            asset_type: "",
            report_by: "",
            current_location_id: "",
            asset_no: "",
            incident_date_from: "",
            incident_date_to: "", 
            bookingTypeList: {
              data: []
            },
            customerList: {
              data: []
            },
            originList: {
              data: []
            }
          }
        },
      mounted() {
        // this.getBookingType();
        this.getCustomer();
        this.getOriginList();
      },
      components: {vSelect, Datepicker},
      methods: {
        search() {
          const data = {
            incident_no: this.incident_no,
            class_type:  this.class_type,
             report_by:  this.report_by,
            asset_type:  this.asset_type,
            current_location_id:  this.current_location_id,
            asset_no:  this.asset_no,
            incident_date_from:  this.incident_date_from,
            incident_date_to:  this.incident_date_to,
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.incident_no = ""
          this.class_type = ""
          this.asset_type = ""
          this.report_by =""
          this.current_location_id = ""
          this.asset_no = ""
          this.incident_date_from = ""
          this.incident_date_to = ""
          const data = {
            incident_no: this.incident_no,
            report_by: this.report_by,
            class_type:  this.class_type,
            asset_type:  this.asset_type,
            current_location_id:  this.current_location_id,
            asset_no:  this.asset_no,
            incident_date_from:  this.incident_date_from,
            incident_date_to:  this.incident_date_to,
          }
          this.$emit("depot-search-query", data)
        },

        getCustomer(){

          axios.get(config.api_path+'/customer?page=1&limit=10000000')
          .then(response => {
            this.customerList = response.data; 
            this.customerList.data.unshift({
              customer_name: 'All',
              id: 'all'
            })
          })

        },

        getOriginList(){

          axios.get(config.api_path+'/setting?setting_type=origin&page=1&limit=10000000')
          .then(response => {
            this.originList = response.data; 
            this.originList.data.unshift({
              setting_name: 'All',
              id: 'all'
            })
          })

        },
      }
    }
</script>

<style scoped>

</style>
